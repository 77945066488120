import React from 'react'

export default function Etiqueta(props) {
    return (
        <div style={{ display: 'flex', justifyContent: 'space-between', margin: '5px' }}>
            <div
                style={{
                    border: '3px solid black',
                    width: '359px',
                    height: '153px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignContent: 'center',
                    alignItems: 'center'
                }}
            >
                <div
                    style={{
                        height: '20%',
                        width: '95%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-around',
                        alignItems: 'center',
                        marginTop: '5px',
                        border: '2px solid black',
                        borderRadius: ' 15px',
                    }}
                >
                    <div style={{ margin: '5px 0', display: 'flex', flexWrap: 'wrap', font: '10.8pt Roboto, sans-serif', fontWeight: 'bold', textAlign: 'center' }}>
                        EMEB "PROFª MARIA BENEDICTA GUIMARÃES"
                    </div>
                </div>
                <div
                    style={{
                        height: '70%',
                        width: '95%',
                        display: 'flex',
                        flexDirection: 'column',
                        margin: '5px 0px 3px 0',
                        border: '2px solid',
                        borderRadius: '15px',
                        justifyContent: 'space-between',
                        alignItems:'center'
                    }}
                >
                    <div
                        style={{
                            fontSize: '13.8pt',
                            fontWeight: 'bold',
                            height: '40%',
                            width: '100%',
                            flexWrap: 'wrap',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            margin: '10px 0px',
                            textAlign: 'center'
                        }}
                    >
                        {props.materia?.toUpperCase()}
                    </div>
                    <div
                        style={{
                            height: '50%',
                            width: '90%',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            fontWeight: 'bold',
                            marginTop: '-5px',
                            marginBottom:'4px',
                            fontSize: '12pt',
                            fontWeight: 'bold',
                            textAlign: 'center',
                            padding: '5px',
                        }}>
                        {props.nomeAluno}
                    </div>
                    <div
                        style={{
                            fontSize: '10pt',
                            height: '50%',
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            fontWeight: 'bold',
                            textAlign: 'center',
                        }}
                    >
                        <div
                            style={{
                                fontSize: '10pt',
                                width: '50%',
                                fontWeight: 'bold',
                                marginTop: '-2px'
                            }}
                        >
                            {props.ano}º ANO {props.turma?.toUpperCase()} - {props.periodo?.toUpperCase()}
                        </div>
                        <div
                            style={{
                                fontSize: '10pt',
                                width: '50%',
                                fontWeight: 'bold',
                                marginTop: '-2px'
                            }}
                        >
                            {props.nomeProfessor}
                        </div>
                    </div>
                </div>
            </div>
        </div >

    )
}