import React from 'react'
import { AiOutlineWhatsApp } from "react-icons/ai"

export default function Modelo2(props) {
    return (
        <div style={{ width: '700px', display: 'flex', justifyContent: 'space-between', margin: '5px' }}>
            <div
                style={{
                    border: '3px solid black',
                    width: '357px',
                    height: '235px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                    marginRight:'2px'
                }}
            >
                <div
                    style={{
                        height: '15%',
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-around',
                        alignItems: 'center',
                        marginTop: '2px'
                    }}
                >
                    <div style={{ margin: '5px 0', display: 'flex', flexWrap: 'wrap', font: '14px Roboto, sans-serif', fontWeight: 'bolder' }}>
                        EMEB "PROFª MARIA BENEDICTA GUIMARÃES"
                    </div>
                    <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                        <div style={{ fontSize: '9pt' }}>(19) 3935-2769</div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <AiOutlineWhatsApp style={{ fontSize: '9pt' }} />
                            <div style={{ fontSize: '9pt' }}>(19) 99955-0173</div>
                        </div>
                    </div>
                </div>
                <div
                    style={{
                        height: '85%',
                        width: '90%',
                        display: 'flex',
                        flexDirection: 'column',
                        margin: '10px',
                        border: '1px solid',
                        borderRadius: '15px'
                    }}
                >
                    <div
                        style={{
                            fontSize: '22pt',
                            fontWeight: 'bold',
                            height: '65%',
                            width: '100%',
                            flexWrap: 'wrap',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            padding: '5px',
                            marginLeft: '-5px'
                        }}
                    >
                        {props.obj.nomeAluno?.toUpperCase()}
                    </div>
                    <div
                        style={{
                            height: '35%',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-around',
                            marginBottom: '2px'
                        }}
                    >
                        <div>Prof: {props.obj.nomeProfessor}</div>
                        <div>Ano/Turma: {props.obj.turma}</div>
                    </div>
                </div>
            </div>
            <div
                style={{
                    border: '3px solid black',
                    width: '357px',
                    height: '235px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                    marginLeft:'2px'
                }}
            >
                <div
                    style={{
                        height: '15%',
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-around',
                        alignItems: 'center',
                        marginTop: '2px'
                    }}
                >
                    <div style={{ margin: '5px 0', display: 'flex', flexWrap: 'wrap', font: '14px Roboto, sans-serif', fontWeight: 'bolder' }}>
                        EMEB "PROFª MARIA BENEDICTA GUIMARÃES"
                    </div>
                    <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                        <div style={{ fontSize: '9pt' }}>(19) 3935-2769</div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <AiOutlineWhatsApp style={{ fontSize: '9pt' }} />
                            <div style={{ fontSize: '9pt' }}>(19) 99955-0173</div>
                        </div>
                    </div>
                </div>
                <div
                    style={{
                        height: '85%',
                        width: '90%',
                        display: 'flex',
                        flexDirection: 'column',
                        margin: '10px',
                        border: '1px solid',
                        borderRadius: '15px'
                    }}
                >
                    <div
                        style={{
                            fontSize: '22pt',
                            fontWeight: 'bold',
                            height: '50%',
                            width: '100%',
                            flexWrap: 'wrap',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                        }}
                    >
                        {props.obj.saida?.toUpperCase()}
                    </div>
                    <div
                        style={{
                            height: '60%',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-around',
                            marginBottom: '2px'
                        }}
                    >
                        <div>Prof: {props.obj.nomeProfessor}</div>
                        <div>Ano/Turma: {props.obj.turma}</div>
                        <div>RA: {props.obj.RA}</div>
                        <div>Validade: Março de {props.obj.anoAtual + 1}</div>
                    </div>
                </div>
            </div>
        </div>

    )
}