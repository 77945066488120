import React from 'react'

export default function CrachaSala(props) {
    return (
        <div style={{ display: 'flex', justifyContent: 'space-between', margin: '5px' }}>
            <div
                style={{
                    border: '3px solid black',
                    width: '312px',
                    height: '194px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignContent: 'center',
                    alignItems: 'center'
                }}
            >
                <div
                    style={{
                        height: '25%',
                        width: '95%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-around',
                        alignItems: 'center',
                        marginTop: '15px',
                        border: '2px solid black',
                        borderRadius: ' 15px',
                    }}
                >
                    <div style={{ margin: '5px 0', display: 'flex', flexWrap: 'wrap', font: '14pt Roboto, sans-serif', fontWeight: 'bolder', textAlign: 'center', }}>
                        EMEB "PROFª MARIA <br /> BENEDICTA  GUIMARÃES"
                    </div>
                </div>
                <div
                    style={{
                        height: '65%',
                        width: '95%',
                        display: 'flex',
                        flexDirection: 'column',
                        margin: '5px 0px 15px 0',
                        border: '2px solid',
                        borderRadius: '15px',
                        justifyContent: 'space-between'
                    }}
                >
                    <div
                        style={{
                            fontSize: props.nomeAluno < 30 ? '20pt' : '16pt',
                            fontWeight: 'bold',
                            height: '65%',
                            width: '100%',
                            flexWrap: 'wrap',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignContent: 'center',
                            textAlign: 'center',
                            margin: '5px 0px',
                            padding: '5px'
                        }}
                    >
                        {props.nomeAluno?.toUpperCase()}
                    </div>
                    <div
                        style={{
                            fontSize: '12pt',
                            height: '25%',
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            fontWeight: 'bold',
                            marginBottom: '5px'
                        }}>
                        <div
                            style={{
                                fontSize: '12pt',
                                height: '50%',
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                fontWeight: 'bold',
                                marginBottom:'1px'
                            }}
                        >
                            PROF. {props.nomeProfessor?.toUpperCase()}
                        </div>
                        <div
                            style={{
                                fontSize: '12pt',
                                height: '50%',
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                fontWeight: 'bold'
                            }}
                        >
                            {props.ano}º ANO {props.turma?.toUpperCase()} - {props.periodo?.toUpperCase()}
                        </div>
                    </div>

                </div>
            </div>
        </div>

    )
}