import React, { useState, useEffect } from 'react'
import './App.css';
import * as xlsx from 'xlsx'
import GlobalStyle from './Styles/global'
import Modelo1 from './Components/CrachaSaida/modelo1'
import Modelo2 from './Components/CrachaSaida/modelo2'
import Modelo3 from './Components/CrachaSaida/modelo3';
import Modelo4 from './Components/CrachaSaida/modelo4';
import Etiqueta from './Components/etiqueta';
import CrachaSala from './Components/crachaSala';


export default function App() {

  const [json, setJason] = useState()
  const [tipo, setTipo] = useState('')
  const [anos, setAnos] = useState([]);
  const [show, setShow] = useState(false)
  const [showUnico, setShowUnico] = useState(false)
  const [turmas, setTurmas] = useState([])
  const [print, setPrint] = useState([])
  const [unicoAluno, setUnicoAluno] = useState({
    nomeAluno: '',
    nomeProfessor: '',
    ano: null,
    turma: '',
    periodo: '',
    RA: '',
    digRA: '',
    saida: '',
    modelo: ''
  })

  const dataAtual = new Date()
  const anoAtual = dataAtual.getFullYear()

  const tipos = [
    {
      id: 1,
      titulo: 'Modelo 1'
    },
    {
      id: 2,
      titulo: 'Modelo 2'
    },
    {
      id: 3,
      titulo: 'Modelo 3'
    },
    {
      id: 4,
      titulo: 'Modelo 4'
    },
  ]

  const objExemplo = {
    nomeAluno: 'Nome do Aluno',
    nomeProfessor: 'Nome do Professor',
    turma: '3º Ano C - Tarde',
    RA: 'XXXXXXXXXXXX-X',
    anoAtual: anoAtual,
    saida: 'Responsável',
  }

  useEffect(() => {
    const stringAnos = localStorage.getItem('anos')
    let anos = [
      {
        ano: 1,
        turmas: []
      },
      {
        ano: 2,
        turmas: []
      },
      {
        ano: 3,
        turmas: []
      },
      {
        ano: 4,
        turmas: []
      },
      {
        ano: 5,
        turmas: []
      }
    ]
    if (stringAnos)
      anos = JSON.parse(stringAnos)
    setAnos(anos)
  }, []);


  const readUploadFile = (e) => {
    e.preventDefault();
    if (e.target.files) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = xlsx.read(data, { type: "array" });
        const json = []
        for (let i = 0; i < workbook.SheetNames.length; i++) {
          const sheetName = workbook.SheetNames[i];
          const worksheet = workbook.Sheets[sheetName];
          let object = xlsx.utils.sheet_to_json(worksheet);
          json.push(object)
        }
        setJason(json)
      };
      reader.readAsArrayBuffer(e.target.files[0]);
    }
  }

  function verifica() {
    let count = 0
    for (let i = 0; i < anos.length; i++) {
      if (!anos[i].turmas.length) return false
      for (let j = 0; j < anos[i].turmas.length; j++) {
        count++
        if (!anos[i].turmas[j].professor) return false
      }
    }
    if (count !== json.length) return false
    return true
  }


  function imprimir() {
    const stringAnos = JSON.stringify(anos)
    localStorage.setItem('anos', stringAnos);
    let turmas = []
    for (let i = 0; i < anos.length; i++) {
      for (let j = 0; j < anos[i].turmas.length; j++) {
        let obj = anos[i].turmas[j]
        obj.ano = i + 1
        turmas.push(obj)
      }
    }
    for (let i = 0; i < turmas.length; i++) {
      turmas[i].alunos = json[i]
    }
    setTurmas(turmas)
    setShow(true)
  }

  function Printable() {
    const loop = [0, 1, 2]
    const materias = [
      "CADERNO DE ARTE",
      "CADERNO DE COMUNICADOS",
      "LIVRO DIDÁTICO",
      "LIVRO DIDÁTICO",
      "LIVRO DIDÁTICO",
      "LIVRO DIDÁTICO",
      "LIVRO DIDÁTICO",
      "LIVRO DIDÁTICO",
      "LIVRO DIDÁTICO",
      "LIVRO DIDÁTICO",
      "LIVRO DIDÁTICO",
      "LIVRO DIDÁTICO",
      "LIVRO DIDÁTICO",
      "CADERNO DE FILOSOFIA",
    ]
    return (
      <div id='printable' style={{ position: 'absolute', width: '780px', height: '1109px', top: '10px', left: '10px' }}>
        {print?.map(turma => {
          return (
            <>
              {turma?.alunos?.map(aluno => {
                const obj = {
                  nomeAluno: aluno.Nome_do_Aluno,
                  nomeProfessor: turma.professor,
                  turma: turma.ano + 'º Ano ' + turma.turma + ' - ' + turma.periodo,
                  RA: aluno.RA + '-' + aluno.Dig_RA,
                  anoAtual: anoAtual,
                  saida: aluno.Saida,
                }
                return (
                  <>
                    {
                      loop?.map(() => {
                        return (
                          <div >
                            {tipo === '1' ?
                              <div>
                                <Modelo1 obj={obj} />
                              </div>
                              :
                              tipo === '2' ?
                                <div>
                                  <Modelo2 obj={obj} />
                                </div>
                                :
                                tipo === '3' ?
                                  <div>
                                    <Modelo3 obj={obj} />
                                  </div>
                                  :
                                  tipo === '4' ?
                                    <div>
                                      <Modelo4 obj={obj} />
                                    </div>
                                    :
                                    null}
                          </div>
                        )
                      })

                    }
                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>

                      {materias?.map((el, index) => {
                        return (
                          <>
                            {(turma.ano === 1 && index === 13) || (turma.ano >= 3 && index === 12) ? null
                              :
                              <div style={{ marginTop: '3px' }}>
                                <Etiqueta
                                  materia={el}
                                  nomeAluno={aluno.Nome_do_Aluno}
                                  nomeProfessor={turma.professor}
                                  ano={turma.ano}
                                  periodo={turma.periodo}
                                  turma={turma.turma}
                                />
                              </div>
                            }
                          </>


                        )
                      })
                      }
                      <div style={{ marginTop: '3px', marginLeft: turma.ano !== 2 ? '7px' : null }}>
                        <CrachaSala
                          nomeAluno={aluno.Nome_do_Aluno}
                          nomeProfessor={turma.professor}
                          ano={turma.ano}
                          periodo={turma.periodo}
                          turma={turma.turma}
                        />
                      </div>
                    </div>
                  </>
                )
              })}
            </>
          )
        })
        }
      </div>
    );
  }


  function adicionaTurma(index) {
    let array = anos
    let objeto = array[index]
    if (!objeto.turmas.length) {
      objeto.turmas.push({
        turma: String.fromCharCode(65),
        periodo: "Manhã",
        ascii: 65,
        professor: '',
      })
    } else {
      let tamanho = objeto.turmas.length
      let ascii = objeto.turmas[tamanho - 1].ascii + 1
      objeto.turmas.push({
        turma: String.fromCharCode(ascii),
        periodo: "Manhã",
        ascii: ascii,
        professor: '',
      })
    }
    array[index] = objeto
    setAnos(() => [...array])
  }

  function removerTurma(index) {
    let array = anos
    array[index].turmas.pop()
    setAnos(() => [...array])
  }

  function mudarPeriodo(indexAno, indexTurma, valor) {
    let array = anos
    array[indexAno].turmas[indexTurma].periodo = valor
    setAnos(() => [...array])
  }

  function mudarProfessor(indexAno, indexTurma, valor) {
    let array = anos
    array[indexAno].turmas[indexTurma].professor = valor
    setAnos(() => [...array])
  }

  function getProfessor(indexAno, indexTurma) {
    let array = anos
    return array[indexAno].turmas[indexTurma].professor
  }

  function geraPDF(ano) {
    const salas = turmas.filter(el => el.ano === ano)
    setPrint(salas)
    setTimeout(() => {
      window.print();
    }, 1000)
  }

  return (
    <>
      <div id='noPrint'>
        {show ?
          <>
            <div style={{ margin: '-17px', position: 'absolute', backgroundColor: 'rgba(0,0,0,0.8)', zIndex: '100', width: '100vw', height: '200vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <div style={{ width: '70%', height: '30%', backgroundColor: 'white', borderRadius: '8px' }}>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <button style={{ margin: '3px', color: 'red', fontWeight: 'bold', backgroundColor: 'transparent', borderColor: 'red', borderRadius: '5px' }} onClick={() => {
                    setShow(false);
                    setTurmas([]);
                    setPrint([])
                  }
                  }>X</button>
                </div>
                <div style={{ marginLeft: '25px', marginBottom: '10px' }}>
                  <h1>Clique no ano que deseja imprimir</h1>
                </div>
                <div style={{ display: 'flex', flexWrap: 'wrap', alignContent: 'center', justifyContent: 'center', height: '60%' }}>
                  {
                    anos?.map(el => {
                      return (
                        <button
                          style={{
                            width: '150px',
                            height: '45px',
                            fontWeight: 'bold',
                            color: 'white',
                            backgroundColor: 'rgb(0,160,0)',
                            borderRadius: '10px',
                            border: 'none',
                            letterSpacing: '1px',
                            margin: '25px'
                          }}
                          onClick={() => geraPDF(el.ano)}
                        >{el.ano}º Ano</button>
                      )
                    })
                  }
                </div>
              </div>
            </div>
          </>
          : null
        }
        {showUnico ?
          <>
            <div style={{ margin: '-17px', position: 'absolute', backgroundColor: 'rgba(0,0,0,0.8)', zIndex: '100', width: '100vw', height: '110vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <div style={{ marginTop: '-10%', width: '450px', height: '550px', backgroundColor: 'white', borderRadius: '8px' }}>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <button style={{ margin: '3px', color: 'red', fontWeight: 'bold', backgroundColor: 'transparent', borderColor: 'red', borderRadius: '5px' }} onClick={() => {
                    setShowUnico(false);
                    setUnicoAluno({
                      nomeAluno: '',
                      nomeProfessor: '',
                      ano: null,
                      turma: '',
                      periodo: '',
                      RA: '',
                      digRA: '',
                      saida: '',
                      modelo: ''
                    })
                  }
                  }>X</button>
                </div>
                <div style={{ marginLeft: '25px', marginBottom: '10px' }}>
                  <h1>Insira as informações do aluno</h1>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', alignContent: 'center', justifyContent: 'center', height: '80%', justifyContent: 'flex-start' }}>

                  <div style={{ margin: '10px', display: 'flex', flexDirection: 'column' }}>
                    <label>Nome do Aluno</label>
                    <input
                      style={{ width: '250px', outline: 'none' }}
                      value={unicoAluno.nomeAluno}
                      onChange={(event) => {
                        const newValue = { nomeAluno: event.target.value }
                        setUnicoAluno(unicoAluno => ({
                          ...unicoAluno,
                          ...newValue
                        }))
                      }} />
                  </div>

                  <div style={{ margin: '10px', display: 'flex', flexDirection: 'column' }}>
                    <label>Nome do Professor</label>
                    <input
                      style={{ width: '150px', outline: 'none' }}
                      value={unicoAluno.nomeProfessor}
                      onChange={(event) => {
                        const newValue = { nomeProfessor: event.target.value }
                        setUnicoAluno(unicoAluno => ({
                          ...unicoAluno,
                          ...newValue
                        }))
                      }} />
                  </div>

                  <div style={{ margin: '10px', display: 'flex', flexDirection: 'column' }}>
                    <label>Turma</label>
                    <div style={{ display: 'flex' }}>
                      <select style={{ marginRight: '5px', outline: 'none' }} value={unicoAluno.ano} onChange={(event) => {
                        const newValue = { ano: event.target.value }
                        setUnicoAluno(unicoAluno => ({
                          ...unicoAluno,
                          ...newValue
                        }))
                      }} >
                        <option value=''></option>
                        <option value={1}>1º Ano</option>
                        <option value={2}>2º Ano</option>
                        <option value={3}>3º Ano</option>
                        <option value={4}>4º Ano</option>
                        <option value={5}>5º Ano</option>
                      </select>
                      <input
                        maxlength="1"
                        style={{ width: '10px', marginRight: '5px', outline: 'none' }}
                        value={unicoAluno.turma}
                        onChange={(event) => {
                          const newValue = { turma: event.target.value?.toLocaleUpperCase() }
                          setUnicoAluno(unicoAluno => ({
                            ...unicoAluno,
                            ...newValue
                          }))
                        }} />
                      -
                      <select
                        style={{ marginLeft: '5px', outline: 'none' }}
                        value={unicoAluno.periodo}
                        onChange={(event) => {
                          const newValue = { periodo: event.target.value }
                          setUnicoAluno(unicoAluno => ({
                            ...unicoAluno,
                            ...newValue
                          }))
                        }} >
                        <option value=''></option>
                        <option value='Manhã'>Manhã</option>
                        <option value='Tarde'>Tarde</option>
                      </select>
                    </div>

                  </div>

                  <div style={{ margin: '10px', display: 'flex', flexDirection: 'column' }}>
                    <label>RA</label>
                    <div style={{ display: 'flex' }}>
                      <input
                        style={{ width: '85px', marginRight: '5px', outline: 'none' }}
                        type="number"
                        value={unicoAluno.RA}
                        onChange={(event) => {
                          const newValue = { RA: event.target.value }
                          if (newValue.RA.length <= 9)
                            setUnicoAluno(unicoAluno => ({
                              ...unicoAluno,
                              ...newValue
                            }))

                        }} />
                      -
                      <input
                        style={{ width: '25px', marginLeft: '5px', outline: 'none' }}
                        value={unicoAluno.digRA}
                        onChange={(event) => {
                          const newValue = { digRA: event.target.value?.toLocaleUpperCase() }
                          if (newValue.digRA.length <= 1)
                            setUnicoAluno(unicoAluno => ({
                              ...unicoAluno,
                              ...newValue
                            }))
                        }} />
                    </div>

                  </div>

                  <div style={{ margin: '10px', display: 'flex', flexDirection: 'column' }}>
                    <label>Saída</label>
                    <input
                      style={{ width: '150px', outline: 'none' }}
                      value={unicoAluno.saida}
                      onChange={(event) => {
                        const newValue = { saida: event.target.value }
                        setUnicoAluno(unicoAluno => ({
                          ...unicoAluno,
                          ...newValue
                        }))
                      }} />
                  </div>


                  <div style={{ margin: '10px', display: 'flex', flexDirection: 'column' }}>
                    <label>Carteirinha</label>
                    <div style={{ display: 'flex' }}>

                      <select
                        style={{ outline: 'none' }}
                        value={tipo}
                        onChange={(event) => {
                          setTipo(event.target.value)
                        }} >
                        <option value=''></option>
                        {tipos?.map(function (response) {
                          return (
                            <option value={response.id} key={response.id}>{response.titulo}</option>
                          )
                        })}
                      </select>
                    </div>
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                      <button
                        style={{
                          width: '150px',
                          height: '45px',
                          fontWeight: 'bold',
                          color: 'white',
                          backgroundColor: 'rgb(0,160,0)',
                          borderRadius: '10px',
                          border: 'none',
                          letterSpacing: '1px',
                          marginTop: '40px',
                        }}
                        onClick={() => {
                          let array = [
                            {
                              ano: parseInt(unicoAluno.ano),
                              turma: unicoAluno.turma,
                              periodo: unicoAluno.periodo,
                              professor: unicoAluno.nomeProfessor,
                              alunos: [
                                {
                                  Dig_RA: unicoAluno.digRA,
                                  Nome_do_Aluno: unicoAluno.nomeAluno,
                                  RA: unicoAluno.RA,
                                  Saida: unicoAluno.saida
                                }
                              ]
                            }
                          ]
                          console.log('array :>> ', array);
                          setPrint(array)
                          setTimeout(() => {
                            window.print();
                          }, 1000)
                        }
                        }
                      > IMPRIMIR
                      </button>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </>
          : null
        }
      </div>
      <div className="App" id='noPrint'>
        <button
          style={{
            width: '150px',
            height: '45px',
            fontWeight: 'bold',
            color: 'white',
            backgroundColor: 'rgb(0,160,0)',
            borderRadius: '10px',
            border: 'none',
            letterSpacing: '1px',
            marginTop: '25px',
            position: 'absolute',
            top: '5px',
            right: '10px'
          }}
          onClick={() => setShowUnico(true)}
        >
          ÚNICO ALUNO
        </button>
        <h2>Insira a planilha</h2>
        <input type='file' accept='.xlsx' onChange={readUploadFile} />
        <h2>Selecione o Tipo de Carteirinha</h2>
        <select
          onChange={(event) => setTipo(event.target.value)}
          value={tipo}
          style={{
            width: '250px',
            height: '50px',
            fontSize: '16pt',
            outline: 'none'
          }}
        >
          <option value=''></option>
          {tipos?.map(function (response) {
            return (
              <option value={response.id} key={response.id} style={{ fontSize: '14pt' }}>{response.titulo}</option>
            )
          })}
        </select>
        <div
          style={{ margin: '25px', display: 'flex', justifyContent: 'center' }}>
          {
            tipo === '1' ? <Modelo1 obj={objExemplo} /> :
              tipo === '2' ? <Modelo2 obj={objExemplo} /> :
                tipo === '3' ? <Modelo3 obj={objExemplo} /> :
                  tipo === '4' ? <Modelo4 obj={objExemplo} /> :
                    null
          }
        </div>
        <>
          <div>
            <h2>Adicione as turmas e os períodos</h2>
          </div>
          <div style={{ display: 'flex', width: '100%', justifyContent: 'center', flexDirection: 'row', flexWrap: 'wrap' }}>
            {anos?.map((ano, indexAno) => {
              return (
                <div style={{ display: 'flex', flexDirection: 'column', margin: '0 25px' }} key={indexAno}>
                  <div style={{ display: 'flex', width: '100%', flexWrap: 'wrap', justifyContent: 'center', alignItems: "center" }}>
                    <h2>{ano.ano}º Ano</h2>
                    {ano.turmas.length < 10 ?
                      <button style={{ height: "50%", margin: "10px" }} onClick={() => adicionaTurma(indexAno)}>+</button>
                      : null}
                    <button style={{ height: "50%", margin: "10px" }} onClick={() => removerTurma(indexAno)}>-</button>
                  </div>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    {ano?.turmas?.map((turma, indexTurma) => {
                      return (
                        <div key={indexTurma}>
                          <div style={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: "center" }}>
                            <h4>{turma.turma}</h4>
                            <select
                              style={{ margin: "10px", outline: 'none' }}
                              onChange={(event) => mudarPeriodo(indexAno, indexTurma, event.target.value)}
                              value={turma.periodo}
                            >
                              <option value="Manhã">Manhã</option>
                              <option value="Tarde">Tarde</option>
                            </select>
                          </div>
                        </div>
                      )

                    })}
                  </div>
                </div>
              )
            })}
          </div>
        </>
        <>
          <div>
            <h2>Digite o nome dos professores das respectivas turmas</h2>
          </div>
          <div style={{ display: 'flex', width: '100%', justifyContent: 'center', flexDirection: 'row', flexWrap: 'wrap' }}>
            {anos?.map((ano, indexAno) => {
              return (
                <div style={{ display: 'flex', flexDirection: 'column', margin: '0 25px' }} key={indexAno}>
                  <div style={{ display: 'flex', width: '100%', flexWrap: 'wrap', justifyContent: 'center', alignItems: "center" }}>
                    <h2>{ano.ano}º Ano</h2>
                  </div>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    {ano.turmas?.map((turma, indexTurma) => {
                      return (
                        <div key={indexTurma}>
                          <div style={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: "center" }}>
                            <h4>{turma.turma}</h4>
                            <input
                              value={getProfessor(indexAno, indexTurma)}
                              type={"text"}
                              style={{ margin: '10px', outline: 'none' }}
                              onChange={event => mudarProfessor(indexAno, indexTurma, event.target.value)}

                            />
                          </div>
                        </div>
                      )

                    })}

                  </div>
                </div>
              )
            })}
          </div>
        </>

        {tipo && json && verifica() ?
          <button
            style={{
              width: '150px',
              height: '45px',
              fontWeight: 'bold',
              color: 'white',
              backgroundColor: 'rgb(0,160,0)',
              borderRadius: '10px',
              border: 'none',
              letterSpacing: '1px',
              marginTop: '25px'
            }}
            onClick={() => imprimir()}
          >
            IMPRIMIR
          </button>
          : null}
      </div>
      <GlobalStyle />
      <Printable />

    </>
  );
}
