import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`

  body, input, button, div {
    font: 14px Roboto, sans-serif;
  }

  button {
    cursor: pointer;
  }

`;