import React from 'react'
import { AiOutlineWhatsApp } from "react-icons/ai"

export default function Modelo1(props) {
    return (
        <div style={{ width: '700px', display: 'flex', justifyContent: 'space-between', margin: '5px' }}>
            <div
                style={{
                    border: '3px solid black',
                    width: '357px',
                    height: '235px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                    marginRight: '2px'
                }}
            >
                <div
                    style={{
                        height: '100%',
                        width: '15%',
                        borderRight: '2px solid black',
                        display: 'flex',
                        flexDirection: 'column',
                        writingMode: 'vertical-rl',
                        justifyContent: 'space-around',
                    }}
                >
                    <div style={{ margin: '4px 2px', display: 'flex', flexWrap: 'wrap', font: '14px Roboto, sans-serif', fontWeight: 'bold' }}>
                        EMEB "PROFª MARIA BENEDICTA GUIMARÃES"
                    </div>
                    <div style={{marginLeft: '2px', width: '50%', display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                        <div style={{ fontSize: '9pt' }}>(19) 3935-2769</div>
                        <div style={{ fontSize: '9pt' }}>
                            <AiOutlineWhatsApp style={{ transform: 'rotateX(180deg)', fontSize: '8pt' }} />
                            (19) 99955-0173
                        </div>
                    </div>
                </div>
                <div
                    style={{
                        height: '180px',
                        width: '85%',
                        display: 'flex',
                        flexDirection: 'column',
                        margin: '15px',
                        border: '1px solid',
                        borderRadius: '15px'
                    }}
                >
                    <div
                        style={{
                            height: '15%',
                            width: '100%',
                        }}
                    />
                    <div
                        style={{
                            fontSize: '22pt',
                            fontWeight: 'bold',
                            height: '65%',
                            width: '100%',
                            flexWrap: 'wrap',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            padding:'5px',
                            marginLeft:'-5px'
                        }}
                    >
                        {props.obj.nomeAluno?.toUpperCase()}
                    </div>
                    <div
                        style={{
                            height: '35%',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-around',
                            marginTop: '15px',
                        }}
                    >
                        <div>Prof: {props.obj.nomeProfessor}</div>
                        <div>Ano/Turma: {props.obj.turma}</div>
                    </div>
                    <div
                        style={{
                            height: '10%',
                            width: '100%',
                        }}
                    />
                </div>
            </div>
            <div
                style={{
                    border: '3px solid black',
                    width: '357px',
                    height: '235px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                    marginLeft: '2px'
                }}
            >
                <div
                    style={{
                        height: '180px',
                        width: '85%',
                        display: 'flex',
                        flexDirection: 'column',
                        margin: '15px',
                        border: '1px solid',
                        borderRadius: '15px'
                    }}
                >
                    <div
                        style={{
                            height: '15%',
                            width: '100%',
                        }}
                    />
                    <div
                        style={{
                            fontSize: '22pt',
                            fontWeight: 'bold',
                            height: '40%',
                            width: '100%',
                            flexWrap: 'wrap',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            padding:'5px',
                            marginLeft:'-5px'
                        }}
                    >
                        {props.obj.saida?.toUpperCase()}
                    </div>
                    <div
                        style={{
                            height: '60%',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-around',
                            marginTop: '15px',
                        }}
                    >
                        <div>Prof: {props.obj.nomeProfessor}</div>
                        <div>Ano/Turma: {props.obj.turma}</div>
                        <div>RA: {props.obj.RA}</div>
                        <div>Validade: Março de {props.obj.anoAtual + 1}</div>
                    </div>
                    <div
                        style={{
                            height: '10%',
                            width: '100%',
                        }}
                    />
                </div>
                <div
                    style={{
                        height: '100%',
                        width: '15%',
                        borderRight: '2px solid black',
                        display: 'flex',
                        flexDirection: 'column',
                        writingMode: 'vertical-rl',
                        justifyContent: 'space-around',
                        transform:'rotate(180deg)'
                    }}
                >
                    <div style={{ margin: '4px 2px', display: 'flex', flexWrap: 'wrap', font: '14px Roboto, sans-serif', fontWeight: 'bold' }}>
                        EMEB "PROFª MARIA BENEDICTA GUIMARÃES"
                    </div>
                    <div style={{ marginLeft: '2px',width: '50%', display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                        <div style={{ fontSize: '9pt' }}>(19) 3935-2769</div>
                        <div style={{ fontSize: '9pt' }}>
                            <AiOutlineWhatsApp style={{ transform: 'rotateX(180deg)', fontSize: '8pt' }} />
                            (19) 99955-0173
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}